jQuery(document).ready(function($) {
	// Mobile menu
	$(".mobile-navigation-menu").mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	$(".mobile-menu-button").click(function() {
		$(".mobile-navigation-menu").data("mmenu").open();
	});

	// Fit Vids
	if ($("body").innerWidth() <= 767) {
		$(".main-content").fitVids({ ignore: ".nofit" });
		$(".homebox").fitVids({ ignore: ".nofit" });
	}

	// Accessible menu
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-link").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".navlinkholder").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".navlinkholder").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	// Webflow
	! function(o, c) {
		var n = c.documentElement,
			t = " w-mod-";
		n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
	}(window, document);


	Webflow.require('ix').init([
		{ slug: "search-dropdown", name: "Search Dropdown", value: { style: {}, triggers: [{ type: "click", selector: ".header-search-container", stepsA: [{ wait: "200ms", display: "block", height: "auto", transition: "height 300ms ease 0" }], stepsB: [{ display: "block", height: "0px", transition: "height 300ms ease 0" }] }, { type: "click", selector: ".dropdownmegamenu", stepsA: [{ opacity: 0, transition: "opacity 400ms ease-out-quad 0" }], stepsB: [] }] } },
		{ slug: "save-mega-fade-in", name: "Save Mega Fade In", value: { style: {}, triggers: [{ type: "hover", selector: ".dropdownmegamenu", stepsA: [{ display: "flex" }, { opacity: 1, transition: "opacity 400ms ease-out-quad 0" }], stepsB: [] }] } },
		{ slug: "save-mega-fade-out", name: "Save Mega Fade Out", value: { style: {}, triggers: [{ type: "hover", stepsA: [], stepsB: [{ opacity: 0, transition: "opacity 400ms ease-out-quad 0" }, { display: "none" }] }] } },
		{ slug: "navlink-underline-grow", name: "NavLink Underline Grow", value: { style: {}, triggers: [{ type: "hover", selector: ".navlinkunderline", descend: true, stepsA: [{ width: "70px", transition: "width 300ms ease-out-quad 0" }], stepsB: [{ width: "0px", transition: "width 300ms ease-out-quad 0" }] }] } },
		{ slug: "mega-fade-out", name: "Mega Fade Out", value: { style: {}, triggers: [{ type: "hover", selector: ".dropdownmegamenu", stepsA: [{ opacity: 0, transition: "opacity 400ms ease-out-quad 0" }], stepsB: [] }] } },
		{ slug: "scrolling-login-drop", name: "Scrolling Login Drop", value: { style: {}, triggers: [{ type: "scroll", selector: ".scrollingloginholder", offsetTop: "10%", offsetBot: "100%", preserve3d: true, stepsA: [{ transition: "transform 400ms ease-out-quad 0", x: "0px", y: "25px", z: "0px" }], stepsB: [{ transition: "transform 400ms ease-in-quad 0", x: "0px", y: "-25px", z: "0px" }] }] } },
		{ slug: "credit-card-slide-in", name: "Credit Card Slide In", value: { style: { opacity: 0, x: "-100px", y: "0px", z: "0px" }, triggers: [{ type: "scroll", offsetBot: "15%", stepsA: [{ opacity: 1, transition: "opacity 800ms ease-out-quad 0, transform 800ms ease-out-quad 0", x: "0px", y: "0px", z: "0px" }], stepsB: [] }] } },
		{ slug: "credit-card-info-slide-in", name: "Credit Card Info Slide In", value: { style: { opacity: 0, x: "100px", y: "0px", z: "0px" }, triggers: [{ type: "scroll", offsetBot: "15%", stepsA: [{ opacity: 1, transition: "opacity 800ms ease-out-quad 0, transform 800ms ease-out-quad 0", x: "0px", y: "0px", z: "0px" }], stepsB: [] }] } },
		{ slug: "icon-box-fade-in", name: "Icon Box Fade In", value: { style: { opacity: 0, x: "0px", y: "40px", z: "0px" }, triggers: [{ type: "load", offsetBot: "0%", stepsA: [{ opacity: 1, transition: "opacity 400ms ease-out-quad 0" }], stepsB: [] }, { type: "load", offsetBot: "35%", stepsA: [{ transition: "transform 400ms ease-out-quad 0", x: "0px", y: "0px", z: "0px" }], stepsB: [] }] } },
		{ slug: "scroll-arrow-animation", name: "Scroll Arrow Animation", value: { style: {}, triggers: [{ type: "load", loopA: true, stepsA: [{ opacity: 1, transition: "opacity 1000ms ease-out-quad 0, transform 1000ms ease-out-quad 0", x: "0px", y: "15px", z: "0px" }, { opacity: 0, transition: "opacity 1000ms ease-out-quad 0, transform 1000ms ease-out-quad 0", x: "0px", y: "30px", z: "0px" }, { x: "0px", y: "-15px", z: "0px" }], stepsB: [] }, { type: "scroll", offsetBot: "35%", stepsA: [{ opacity: 0, transition: "opacity 450ms ease 0" }], stepsB: [] }] } },
		{ slug: "left-nav-red-diamond", name: "Left Nav Red Diamond", value: { style: {}, triggers: [{ type: "hover", selector: ".left-nav-orange-dot", descend: true, stepsA: [{ opacity: 1, transition: "opacity 200 ease 0" }], stepsB: [{ opacity: 0, transition: "opacity 200 ease 0" }] }] } },
		{ slug: "button-slide-right", name: "Button Slide Right", value: { style: {}, triggers: [{ type: "hover", stepsA: [{ transition: "transform 450ms ease-out-quad 0", x: "5px", y: "0px", z: "0px" }], stepsB: [{ transition: "transform 450ms ease-out-quad 0", x: "0px", y: "0px", z: "0px" }] }] } }
	]);
});

function goToLoginForm() {
	window.scrollTo(0, 0);
	$(".login-id-field").focus();
}



